export const menuItems = [
  {
    path: "/",
    text: "inicio",
  },
  {
    path: "/sobre-nosotros",
    text: "sobre nuestra galería",
  },
  {
    path: "/eventos",
    text: "eventos",
  },
  {
    path: "/contacto",
    text: "contacto",
  },
]
